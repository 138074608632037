// utility class to add the wave shape to the top or bottom of an element
// note: wave.svg could be made into a mixin so the color can be altered

.wave-background-top::before,
.wave-background-bottom::after {
	--wave-original-height: 26;
	--wave-original-width: 72;
	--wave-aspect-ratio: calc(
		var(--wave-original-width) / var(--wave-original-height)
	);
	--wave-width: max(5vw, 2.34375rem);
	--wave-height: calc(var(--wave-width) / var(--wave-aspect-ratio));

	content: '';

	display: block;
	// use a reduced height and cut off overflow to prevent artifacts due to pixel rounding
	height: calc(22 / var(--wave-original-height) * var(--wave-height));
	overflow: hidden;

	background-image: var(--wave-background-svg, url('/svgs/wave-seafoam.svg'));
	background-repeat: repeat-x;
	background-size: var(--wave-width) var(--wave-height);
	// negative offset to prevent gaps appearing due to pixel rounding
	background-position-y: calc(
		-3 / var(--wave-original-height) * var(--wave-height)
	);
}

.wave-background-top::before {
	transform: rotate(180deg) translateY(-0.5px);
}
