@font-face {
	font-family: 'Serial';
	font-weight: 400;
	src: url('/fonts/SerialAOutput-Regular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Everett';
	font-weight: 400;
	src: url('/fonts/TWKEverett-Regular-web.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Everett';
	font-style: italic;
	font-weight: 400;
	src: url('/fonts/TWKEverett-RegularItalic-web.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Everett';
	font-weight: 700;
	src: url('/fonts/TWKEverett-Bold-web.woff2') format('woff2');
	font-display: swap;
}
