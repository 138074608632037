.container {
	/* Ensure the container sits on top of the rest of the website */
	z-index: 2;
}

.container,
.overlay {
	position: fixed;
	height: 100%;

	/* Using 100svh provides a better experience when hiding the toolbar */
	/* stylelint-disable-next-line unit-no-unknown */
	height: 100svh;
	inset: 0;
}

.container:focus {
	/* This is very important in order to prevent performance issues in Safari */
	outline: none;
}

.overlay {
	z-index: 1;

	background: var(--dialog-overlay-background, transparent);
	backdrop-filter: var(--dialog-overlay-backdrop-filter, none);
}

.container[aria-hidden='true'],
.container[aria-hidden='true'] .overlay {
	display: none;

	pointer-events: none;
}

.dialog {
	position: relative;
	z-index: 2;
	height: inherit;
	overflow: hidden;
}

.container:not([aria-hidden='true']) .dialog {
	overflow: hidden scroll;
}

.closeButton {
	position: absolute;
	top: var(--spacing-sm);
	right: var(--spacing-sm);
}

.closeButton > svg {
	width: var(--dialog-close-button-icon-size);
	height: var(--dialog-close-button-icon-size);

	fill: currentColor;
}
