@use 'variables';

.content {
	display: block;
	position: relative;
	padding: var(--navigation-fullscreen-open-button-content-padding-block)
		var(--spacing-2xs);
	overflow: hidden;
}

.content:not(.hide-bubble)::before {
	content: '';

	display: block;
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	transform: rotate(15deg);

	background-color: var(--body-background-color-hex, var(--color-core-sand));
	clip-path: ellipse(50% 45% at 50% 50%);
}

:global(#body-events) .content::before {
	background-color: var(--color-accent-seafoam);
}

.content > svg {
	display: block;
	position: relative;
	z-index: 2;
	width: var(--navigation-fullscreen-open-button-content-icon-size);
	height: var(--navigation-fullscreen-open-button-content-icon-size);
}

.content.override-mobile-open-button-color-sand > svg {
	fill: var(--color-core-sand);
}

@media (min-width: variables.$breakpoint-md) {
	.content {
		padding: var(--navigation-fullscreen-open-button-content-padding-block)
			var(--navigation-fullscreen-open-button-content-padding-inline);
		&.override-mobile-open-button-color-sand > svg {
			fill: var(--color-core-black);
		}
	}
}
