@use 'variables';

/* Typography styles */
@mixin font-t1 {
	font: variables.$font-size-t1 / variables.$line-height-t1
		var(--font-stack-title);
	text-transform: uppercase;
}

@mixin font-t2 {
	font: variables.$font-size-t2 / variables.$line-height-t2
		var(--font-stack-title);
	text-transform: uppercase;
}

@mixin font-t3($weight: 400) {
	@if ($weight and not ($weight == 400 or $weight == 700)) {
		@error '$weight should be 400 or 700';
	}

	font: $weight variables.$font-size-t3 / variables.$line-height-t3
		var(--font-stack-sans);
}

@mixin font-t3-title {
	font: variables.$font-size-t3 / variables.$line-height-t3-title
		var(--font-stack-title);
	text-transform: uppercase;
}

@mixin font-t4($weight: null) {
	@if ($weight and not ($weight == 400 or $weight == 700)) {
		@error '$weight should be 400 or 700';
	}

	font: $weight variables.$font-size-t4 / variables.$line-height-t4
		var(--font-stack-sans);
}

@mixin font-t5($weight: null, $style: null) {
	@if ($weight and not ($weight == 400 or $weight == 700)) {
		@error '$weight should be 400 or 700';
	}

	@if ($style and not ($style == italic)) {
		@error '$style should be italic or omitted';
	}

	font: $style $weight variables.$font-size-t5 / variables.$line-height-t5
		var(--font-stack-sans);
}

@mixin font-t6($weight: 400) {
	@if ($weight and not ($weight == 400 or $weight == 500 or $weight == 700)) {
		@error '$weight should be 400, 500 or 700';
	}

	font: $weight variables.$font-size-t6 / variables.$line-height-t6
		var(--font-stack-sans);

	@if $weight == 500 {
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}
}

@mixin font-t7 {
	font: 400 #{0.75rem} / variables.$line-height-t7 var(--font-stack-sans);
}

@mixin stack($size) {
	& > * + * {
		margin-top: var(--spacing-#{$size});
	}
}

@mixin stack-vertical($size) {
	& > * + * {
		margin-left: var(--spacing-#{$size});
	}
}

@mixin unstack {
	& > * + * {
		margin-top: 0;
	}
}

@mixin unstack-vertical {
	& > * + * {
		margin-left: 0;
	}
}

@mixin no-font-smoothing {
	@media (min-resolution: 2dppx) {
		-moz-osx-font-smoothing: subpixel-antialiased;
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

@mixin breakout($position: 'before') {
	&::#{$position} {
		content: '';

		position: absolute;
		z-index: var(--breakout-z-index, 1);

		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

@mixin arrow-add-on($position: 'after', $arrow-style: 'right') {
	& {
		display: inline-flex;
		align-items: center;
	}

	@if ($arrow-style == 'right') {
		&::#{$position} {
			content: '';

			display: block;
			flex-shrink: 0;
			width: 0.9em;
			height: 1em;
			// Adjust alignment to the text:
			margin-top: 0.2em;

			@if ($position == 'before') {
				margin-right: var(--spacing-xs);

				transform: rotate(180deg);
			} @else {
				margin-left: var(--spacing-xs);
			}

			background: currentColor;

			// By using a mask we can adjust the color of the arrow:
			mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg" id="arrow"><path d="M0 8l16.646-.504L12 14h1l7-7-7-7h-1l4.646 6.504L0 6v2z"></path></svg>');

			mask-repeat: no-repeat;
			mask-position: center;
		}
	} @else if ($arrow-style == 'diagonal') {
		&::#{$position} {
			content: '';

			display: block;
			flex-shrink: 0;
			width: 0.7em;
			height: 0.6em;
			// Adjust alignment to the text:
			margin-top: 0.1em;

			@if ($position == 'before') {
				margin-right: var(--spacing-xs);

				transform: rotate(180deg);
			} @else {
				margin-left: var(--spacing-xs);
			}

			background: currentColor;

			// By using a mask we can adjust the color of the arrow:
			mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" id="arrow"><path d="M1.624 2.168L3.448 0.44H11.92V8.912L10.192 10.736V3.392L2.056 11.528L0.832 10.304L8.968 2.168H1.624Z"/></svg>');

			mask-repeat: no-repeat;
			mask-position: center;
		}
	} @else {
		@error '$arrow-style should be right or diagonal';
	}
}

// This could be replaced by ::marker once it has full support in Safari https://developer.mozilla.org/en-US/docs/Web/CSS/::marker
@mixin perk-tick {
	&::before {
		content: url('/svgs/perk-tick.svg');

		display: inline-block;
		width: 1.25rem;
		height: 1.25rem;
		margin-right: 0.5rem;
	}
}
