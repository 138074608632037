@use 'mixins';
@use 'variables';

.link-wrapper {
	display: flex;
	align-items: center;
	gap: var(--spacing-xs);

	padding: var(--spacing-xs);

	.login,
	.my-account {
		@include mixins.font-t6($weight: 700);
	}

	&--cart-visible .login {
		display: none;
	}

	.user-icon {
		display: block;
		width: 1.2rem;
		height: calc(1.75 / 1.625 * 1.2rem);

		fill: currentColor;
	}
}

.navigation-addon {
	display: flex;

	&.navigation-addon--tier-level {
		&-1 {
			color: var(--color-accent-ocean);
		}
		&-2 {
			color: var(--color-accent-strawberry);
		}
		&-3 {
			color: var(--color-accent-amethyst);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&--hide-label-on-desktop {
			.login,
			.my-account {
				display: none;
			}
		}

		&--override-desktop-color-sand {
			--cart-button-icon-fill: var(--color-core-sand);
			--cart-button-quantity-color: var(--color-core-black);

			color: var(--color-core-sand) !important;
		}

		&--override-desktop-color-black {
			color: var(--color-core-black) !important;
		}
	}
}

@media (min-width: variables.$breakpoint-xs) {
	.navigation-addon {
		gap: var(--spacing-xs);
	}
}
@media (min-width: 27em) {
	// uses custom breakpoint to hide "sign in label" as late as possible to prevent overflow
	.user-icon:not(.user-icon--cart-visible) {
		display: block;
	}
	.link-wrapper--cart-visible .login {
		display: block;
	}
}
@media (min-width: variables.$breakpoint-sm) {
	.user-icon {
		width: 1.625rem;
		height: 1.75rem;
	}
}
@media (min-width: variables.$breakpoint-md) {
	.link-wrapper {
		.login,
		.my-account {
			@include mixins.font-t5($weight: 700);
		}
		.user-icon {
			width: 1.4rem;
			height: calc(1.75 / 1.625 * 1.4rem);
		}
	}
}
