@use 'variables';

.openButton {
	display: none;
	touch-action: manipulation;

	margin-right: calc(
		var(--navigation-fullscreen-open-button-content-padding-inline) * -1
	);
}

.container {
	/* Ensure the container sits on top of the rest of the website */
	z-index: 2;
}

.container,
.overlay {
	position: fixed;
	height: 100%;

	/* Using 100svh provides a better experience when hiding the toolbar */
	/* stylelint-disable-next-line unit-no-unknown */
	height: 100svh;
	inset: 0;
}

.container:focus {
	/* This is very important in order to prevent performance issues in Safari */
	outline: none;
}

.overlay {
	z-index: 1;

	background: var(--dialog-overlay-background, transparent);
	backdrop-filter: var(--dialog-overlay-backdrop-filter, none);
}

.container[aria-hidden='true'],
.container[aria-hidden='true'] .overlay {
	display: none;

	pointer-events: none;
}

.dialog {
	position: relative;
	z-index: 2;
	height: inherit;
	padding: var(--spacing-sm);
	padding-top: 0;
	overflow: hidden;

	background-color: var(--color-core-black);

	color: var(--color-core-sand);
}

.container:not([aria-hidden='true']) .dialog {
	overflow: hidden scroll;
}

.closeButton {
	position: absolute;
	top: calc(
		(var(--navigation-height) - var(--dialog-close-button-icon-size)) / 2
	);
	right: var(--navigation-fullscreen-open-button-content-padding-inline);
}

.closeButton > svg {
	width: var(--dialog-close-button-icon-size);
	height: var(--dialog-close-button-icon-size);

	fill: currentColor;
}

.main {
	flex: 1;
}

@media (min-width: variables.$breakpoint-sm) {
	.dialog {
		padding: var(--spacing-md);
		padding-top: 0;
	}
	.closeButton {
		right: calc(
			var(--navigation-fullscreen-open-button-content-padding-inline) +
				var(--spacing-sm)
		);
	}
}
@media (min-width: variables.$breakpoint-md) {
	.openButton {
		display: block;
	}

	.closeButton {
		right: var(--spacing-lg);
	}

	.dialog {
		padding: var(--spacing-lg);
		padding-top: 0;
	}
}
