.scroll-lock {
	padding-inline-end: var(--scroll-lock-scrollbar-gutter, 0);
	overflow: hidden;
}

/* iOS */
@supports (-webkit-touch-callout: none) {
	.scroll-lock {
		position: fixed;
		top: var(--scroll-lock-scroll-offset, 0);
		left: 0;
		width: 100%;
	}
}
