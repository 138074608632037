@use 'mixins';

.location {
	@include mixins.stack($size: sm);
}

.heading {
	@include mixins.font-t4($weight: 700);
}

.link {
	@include mixins.font-t5;
	transition:
		text-decoration-color 200ms ease-in-out,
		-webkit-text-decoration-color 200ms ease-in-out;

	text-decoration-color: transparent;
	&:hover {
		text-decoration-color: currentColor;
	}
}
