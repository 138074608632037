@use 'variables';

.content {
	padding: var(--spacing-sm);
}

.list-item {
	padding: var(--spacing-sm) 0;
}

.is-first-list-item:not(:first-child) {
	border-top: 1px solid;
}

@media (min-width: variables.$breakpoint-md) {
	.content {
		padding: var(--spacing-sm) var(--spacing-md);
	}
}
