@use 'mixins';

.item {
	display: flex;
	position: relative;
	gap: 1.5em;
}

.body {
	flex: 1 0 max(10rem, 40%);
}

.title {
	@include mixins.font-t3-title;
}

.subtitle {
	@include mixins.font-t6($weight: 500);

	margin-top: var(--spacing-xs);
}

.remove-button {
	@include mixins.font-t7;

	display: block;
	position: relative;
	z-index: 2;

	// adjust alignment due to increased tapable area
	margin-left: calc(var(--spacing-xs) * -1);

	// increase tapable area
	padding: var(--spacing-xs);

	font-size: 0.8125rem;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 0.125em;
}

.body,
.image-frame {
	position: relative;
}

.image-frame {
	padding-bottom: 100%;
}

.image-frame > * {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.price-tag {
	position: absolute;
	top: 0;
	right: 0;
}

.link {
	--breakout-z-index: 2;
}

@media (hover: hover) {
	.remove-button:hover {
		text-decoration: none;
	}

	.item:hover {
		--shop-price-tag-animation-play-state: running;
	}
}
