@use 'mixins';
@use 'variables';

.is-compact .content {
	display: grid;
	gap: var(--spacing-sm);

	.title {
		@include mixins.font-t4($weight: 700);
	}

	.text > * {
		max-width: 40ch;
	}
}

.newsletter-section:not(.is-compact) {
	padding: var(--spacing-md) 0 var(--spacing-lg);

	border-color: var(--text-color, inherit);

	background-color: var(--background-color, transparent);

	color: var(--text-color, inherit);

	.title {
		@include mixins.font-t2;
	}

	.text {
		margin: var(--spacing-sm) var(--spacing-sm) 0;
	}

	.form {
		margin: var(--spacing-md) var(--spacing-sm) 0;
	}

	.image {
		margin-top: var(--spacing-md);
	}

	@media (min-width: variables.$breakpoint-md) {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		padding: var(--spacing-xl) 0;

		&.is-alternate {
			flex-direction: row;

			.title {
				text-align: left;
			}
		}

		.form {
			max-width: 78%;
		}

		&.center-align {
			justify-content: center;

			text-align: center;

			.form {
				margin: var(--spacing-md) auto 0;
			}
		}

		.content {
			padding: 0 var(--spacing-lg);

			&:not(:only-child) {
				flex: 1 0 48%;
			}
		}

		.image {
			flex: 1 0 52%;
			margin-top: 0;
		}
	}
}
