@use 'variables';

// Default content-wrapper to prevent repeating these styles.
.content-wrapper {
	padding: var(--spacing-sm);
}

@media (min-width: variables.$breakpoint-md) {
	.content-wrapper {
		padding: var(--spacing-md) var(--spacing-lg);
	}
}
