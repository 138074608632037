@use 'variables';
@use 'keyframes';

.tag {
	--shop-price-tag-size: 3ch;
	--shop-price-tag-translate: none;

	// Allow hiding of the price tag (revert by using `--shop-price-tag-display: revert;`)
	display: var(--shop-price-tag-display, grid);
	grid-template: 1fr;
	max-width: min-content;
}

.amount,
.badge {
	grid-area: 1 / 2;
}

.amount {
	display: grid;
	z-index: 1;
	box-sizing: content-box;
	width: var(--shop-price-tag-size);
	height: var(--shop-price-tag-size);
	padding: 0.75em;
	place-content: center;
}

.badge {
	// Visual corrections for line-height happen on `.badge`
	transform: translateY(0.0375em);
}

.badge::after {
	content: '';

	display: block;

	width: 100%;
	height: 100%;

	background-color: var(--color-accent-watermelon);
	mask-image: url(/svgs/price-tag.svg);
	mask-size: cover;
}

/* stylelint-disable-next-line at-rule-no-vendor-prefix */
@-moz-document url-prefix() {
	// Due to line-height difference in FireFox we need this media query to target FireFox only
	.badge {
		transform: translateY(-0.0675em);
	}
}

@media (min-width: variables.$breakpoint-sm) {
	.amount {
		padding: 0.5em;
	}
}

@media (prefers-reduced-motion: no-preference) {
	@media not all and (min-width: variables.$breakpoint-sm) {
		// Make price tag alway spin on smaller screens
		.badge::after {
			--shop-price-tag-animation-play-state: running;
		}
	}

	.badge::after {
		animation: spin 7s linear infinite;
		animation-play-state: var(--shop-price-tag-animation-play-state, paused);
	}
}
