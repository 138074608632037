@use 'mixins';

.badge {
	@include mixins.font-t6($weight: 500);

	display: inline-flex;
	align-items: center;
	padding: 0.25em 0.625em;

	border: 1px solid var(--badge-border-color, currentColor);
	border-radius: 2px;

	background-color: var(--badge-background-color, transparent);

	color: var(--badge-text-color, currentColor);
	gap: 0.375em;
}

.badge--rounded {
	border-radius: 1em;
}

.badge--elsewhere-presents {
	--badge-background-color: var(--color-core-black);

	min-width: 0;
	padding-right: 0;
	padding-left: 0;

	border: 0;
}
