@use 'mixins';
@use 'variables';

.message {
	padding: var(--spacing-lg) 0 var(--spacing-sm);

	&__heading {
		@include mixins.font-t1;
	}

	&__text {
		max-width: 40ch;
		margin-top: var(--spacing-sm);
		padding: 0 var(--spacing-sm);
	}

	@media (min-width: variables.$breakpoint-md) {
		padding: var(--spacing-xl) 0 var(--spacing-md);

		&__text {
			padding: 0 var(--spacing-lg);
		}
	}
}
