@use 'mixins';

.arrow-add-on {
	@include mixins.arrow-add-on();
}

.arrow-add-on-before {
	@include mixins.arrow-add-on($position: 'before');
}

.arrow-add-on-after {
	@include mixins.arrow-add-on($position: 'after');
}

// Force no-arrow to remove arrow added by .anchor[href*="://"]
.no-arrow-add-on {
	display: initial !important;
}

.no-arrow-add-on::before,
.no-arrow-add-on::after {
	display: none !important;
}
