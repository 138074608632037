.markdown > * + * {
	margin-top: 1em;
}

.markdown :is(h2, h3, h4, h5, h6):not(:first-child) {
	margin-top: 2em;

	font-weight: 700;
}

.markdown em {
	font-style: italic;
}
