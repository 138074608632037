@use 'mixins';

.form-fieldset > * + * {
	margin-top: var(--spacing-md);
}

.form-field {
	@include mixins.stack($size: xs);
	display: block;
}

.form-label {
	display: block;

	font-weight: 700;
}

.form-fieldset[disabled] .form-input,
.form-input[disabled] {
	opacity: 0.5;

	cursor: not-allowed;
}

.form--inline {
	display: inline;
}
