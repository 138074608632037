@use 'mixins';
@use 'variables';

.membership-anchor {
	@include mixins.font-t6($weight: 700);
}

@media (min-width: variables.$breakpoint-md) {
	.membership-anchor {
		@include mixins.font-t5($weight: 700);
	}
}
