@use 'variables';

.grid {
	display: block;
	padding-right: var(--spacing-sm);
	padding-left: var(--spacing-sm);

	@media (min-width: variables.$breakpoint-md) {
		display: grid;
		grid-template-columns:
			var(--spacing-lg)
			repeat(32, [col-start] 1fr)
			var(--spacing-lg);
		padding-right: 0;
		padding-left: 0;

		> * {
			grid-column: col-start 1 / span 32;
		}
	}
}

.grid-col-2 > :nth-child(odd),
.col-1-2 {
	grid-column: col-start 1 / span 15;
}

.grid-col-2 > :nth-child(even),
.col-2-2 {
	grid-column: col-start 18 / span 15;
}
