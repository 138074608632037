@use 'mixins';
@use 'variables';
@use 'functions';

.content {
	padding: var(--spacing-md) var(--spacing-md) var(--spacing-lg);

	@include mixins.stack($size: sm);
}

.heading {
	@include mixins.font-t4($weight: 700);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-xs);

	.warning-symbol {
		align-self: center;
		width: 1.35em;
		height: 1.25em;
		margin-right: calc(var(--spacing-xs) + var(--spacing-2xs));

		vertical-align: sub;
	}

	span {
		align-self: baseline;
	}
}

.copy {
	@include mixins.font-t5;
}

.email {
	line-break: anywhere;
}

@media (min-width: variables.$breakpoint-sm) {
	.heading {
		display: inherit;
	}
	.warning-symbol {
		align-self: unset;
	}
}
