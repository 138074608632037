.map-drawing {
	&__svg {
		--width: 10em;
		width: 10em;
		height: calc(var(--width) / (var(--map-drawing-aspect-ratio)));

		color: var(--highlight-color);
		vertical-align: top;
	}
}
