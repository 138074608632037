.select {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 20"><path fill="black" d="m6 0 .504 16.646L0 12v1l7 7 7-7v-1l-6.504 4.646L8 0H6Z"/></svg>');
	background-repeat: no-repeat;
	background-position: center right 1em;
	background-size: 0.63em 0.9em;
}

.select > select {
	cursor: pointer;
}
