@use 'mixins';

*,
::before,
::after {
	box-sizing: border-box;
}

* {
	margin: 0;
}

html {
	-webkit-text-size-adjust: 100%;

	-webkit-font-smoothing: antialiased;
}

body {
	@include mixins.font-t5;

	background-color: var(--body-background-color-hex, var(--color-core-sand));

	color: var(--color-core-black);

	// hack for safari to force updating of fluid lengths
	letter-spacing: 0vw; /* stylelint-disable-line length-zero-no-unit */
}

button,
fieldset {
	padding: 0;

	border: none;
}

input,
button,
select,
textarea {
	background-color: transparent;

	color: inherit;
	font: inherit;
	appearance: none;
}

label,
button {
	cursor: pointer;
}

img,
video {
	width: 100%;
	height: auto;

	vertical-align: middle;
}

input,
select,
textarea {
	width: 100%;
	padding: var(--spacing-sm);

	border: 1px solid;
	border-radius: 0;
}

textarea {
	resize: none;
}

::placeholder {
	opacity: 1;

	color: currentColor;
}

:not(.keyboard-focus) *:focus {
	outline: none;
}

.keyboard-focus *:focus,
.keyboard-focus input.sr-only:focus ~ label {
	outline: 2px var(--color-brand-baby-blue) solid;
}

a {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font: inherit;
}

em {
	font-style: normal;
}

strong {
	font-weight: 700;
}

picture {
	display: block;
}

iframe {
	border: 0;
}

dialog {
	display: none;
}

dialog[open] {
	display: block;
}

.grecaptcha-badge {
	visibility: hidden;
}
