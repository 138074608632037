@use 'variables';
@use 'mixins';

.footer {
	@include mixins.stack($size: md);

	padding: var(--spacing-md);
}

:global(#body-home) .footer {
	background-color: var(--color-core-sand);
}

.footer h2 {
	@include mixins.font-t5($weight: 700);
}

.primary ul {
	li {
		margin-top: var(--spacing-xs);
		&:first-child {
			margin-top: var(--spacing-sm);
		}
	}
}

.section {
	padding-top: var(--spacing-md);

	border-top: 1px solid var(--color-core-black);
}

.primary,
.secondary {
	@include mixins.stack($size: md);
}

.secondary * {
	@include mixins.font-t6($weight: 500);
}

.copyright {
	@include mixins.stack($size: sm);

	--logo-width: 8rem;
}

.copyright > svg {
	display: block;
	width: var(--logo-width);
	height: calc(var(--logo-width) / (var(--logo-aspect-ratio)));
}

.info-pages {
	@include mixins.font-t5;

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: var(--spacing-sm);
}

@media (min-width: variables.$breakpoint-md) {
	.footer {
		padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
		@include mixins.stack($size: lg);
	}
	.primary,
	.secondary,
	.copyright {
		@include mixins.unstack;
	}

	.primary {
		display: flex;
		align-items: flex-start;
		gap: var(--spacing-md);
	}

	.newsletter form {
		max-width: 18em;
	}

	.copyright {
		--logo-width: 12rem;

		display: flex;
		align-items: baseline;

		gap: var(--spacing-md);
	}

	.copyright > p {
		// make sure we align the copyright line centered with the logo
		// and on the baseline with the info pages.
		line-height: calc(var(--logo-width) / (var(--logo-aspect-ratio)));
	}

	.copyright > svg {
		align-self: center;
	}

	.internal-links {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	.external-links {
		display: grid;
		grid-template-columns: repeat(4, [col-start] 1fr);

		.newsletter {
			grid-column: col-start / span 2;
		}
		.location {
			grid-column: col-start 3 / span 1;
		}
		.latest,
		.explore,
		.community,
		.about,
		.social {
			grid-column: col-start 4 / span 1;
		}
	}

	.secondary {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		justify-content: space-between;
		gap: var(--spacing-md);
	}

	.info-pages {
		gap: var(--spacing-md);

		flex-direction: row;
	}
}
