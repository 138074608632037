.newsletter-form {
	display: flex;

	border: 1px solid;
}

.newsletter-form__input {
	padding-right: 0;

	border: 0;
}

.newsletter-form__submit {
	padding: 0 1em;
}

.newsletter-form__arrow {
	--aspect-ratio: var(--arrow-aspect-ratio);
	--width: 1em;

	width: var(--width);
	height: calc(var(--width) / (var(--aspect-ratio)));

	fill: currentColor;
}

.newsletter-form__input:disabled,
.newsletter-form__submit:disabled .newsletter-form__arrow {
	opacity: 0.5;
}
