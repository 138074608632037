@use 'mixins';
@use 'variables';

.content {
	--cart-button-icon-width: 2.25rem;
	--cart-button-icon-aspect-ratio: (42 / 29);

	position: relative;
}

.quantity {
	position: absolute;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0.45em 0 0 0.8em;

	color: var(--cart-button-quantity-color, var(--color-core-sand));

	@include mixins.font-t6($weight: 700);
	font-size: 0.54em;
}

.icon {
	display: block;
	width: var(--cart-button-icon-width);
	height: calc(
		var(--cart-button-icon-width) / var(--cart-button-icon-aspect-ratio)
	);

	fill: var(--cart-button-icon-fill, var(--color-core-black));
}

@media (min-width: variables.$breakpoint-sm) {
	.content {
		--cart-button-icon-width: 2.75rem;
	}

	.quantity {
		font-size: 0.75rem;
	}
}
