@use 'variables';
@use 'mixins';

.navigation-anchor-primary {
	display: block;
	position: relative;
	margin-bottom: var(--spacing-sm);
	overflow: hidden;

	text-align: center;
	@media (min-width: variables.$breakpoint-lg) {
		margin-bottom: var(--spacing-md);

		text-align: left;
	}

	&__label {
		@include mixins.font-t2;

		position: relative;
		z-index: 2;

		text-align: center;
	}

	&__arrow {
		display: none;

		@media (min-width: variables.$breakpoint-md) {
			--width: 1em;
			--height: calc(var(--width) / (var(--hand-drawn-arrow-5-aspect-ratio)));
			position: absolute;
			top: calc(50% - var(--height) / 2 + 5%);
			right: calc(-1 * var(--width) - var(--spacing-sm));
			width: var(--width);
			height: var(--height);

			fill: var(--background-color, var(--color-core-strawberry));
		}
	}

	&:hover {
		color: var(--background-color, var(--color-core-strawberry));

		@media (min-width: variables.$breakpoint-md) {
			.navigation-anchor-primary__arrow {
				display: inline-block;
			}
		}
	}
}
