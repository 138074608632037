@use 'mixins';

.anchor,
.markup a {
	transition: text-decoration-color 200ms ease-in-out;

	text-decoration: underline;
	text-decoration-thickness: var(--text-decoration-thickness);
	text-underline-offset: var(--text-underline-offset);

	text-decoration-color: currentColor;
	word-break: break-word;
	overflow-wrap: anywhere;
}

@media (hover: hover) {
	.anchor:hover,
	.markup a:hover {
		text-decoration-color: transparent;
	}
}

@supports (mask-image: url('')) {
	.anchor--external {
		@include mixins.arrow-add-on($position: 'after');
	}
	.anchor--external-arrow-style-diagonal {
		@include mixins.arrow-add-on($position: 'after', $arrow-style: 'diagonal');
		width: max-content; // avoids anchor text line breaking so the arrow position is consistent
	}
}
