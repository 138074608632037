@use 'mixins';
@use 'variables';

// default colors can be overridden using the following custom properties on a parent component
// --button-background-color,
// --button-text-color
// --button-border-color
// --button-border-radius

.button {
	// keep the button surface the same also when border-radius is applied
	@include mixins.breakout;

	display: block;
	position: relative;
	padding: 1em;

	transition: border-radius 200ms ease;

	border: 1px solid var(--button-border-color, currentColor);

	border-radius: var(--button-border-radius, 0);

	background-color: var(--button-background-color, transparent);

	color: var(--button-text-color, var(--color-core-black));

	line-height: 1.4;
	text-align: center;

	&--elsewhere-presents {
		--button-text-color: var(--color-core-sand);
		width: 100%;
		padding: var(--spacing-sm) var(--spacing-md);
	}
}

.button--full-width {
	width: 100%;
}

[disabled] .button,
.button[disabled] {
	opacity: 0.5;

	cursor: not-allowed;
}

@media (hover: hover) {
	[disabled] .button {
		--button-border-radius: 0 !important;
	}

	.button:not([disabled], :active):hover {
		--button-border-radius: 100%;
	}
}
