@use 'variables';
@use 'mixins';

.arrow-decoration {
	display: none;

	@media (min-width: variables.$breakpoint-lg) {
		--width: 12em;
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		width: var(--width);
		height: calc(var(--width) / (var(--hand-drawn-arrow-1-aspect-ratio)));

		transform: scaleX(-1) rotate(45deg) translate(2em, -4em);

		fill: var(--color-accent-seafoam);
	}
}
