@use 'variables';
@use 'mixins';

.navigation-fullscreen-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--navigation-height);
	margin-right: calc(var(--dialog-close-button-icon-size) + var(--spacing-md));
	margin-bottom: var(--spacing-xs);

	svg {
		fill: var(--color-core-sand);
	}

	span {
		color: var(--color-core-black);
	}

	&__anchor-list {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__anchor-list > * {
		display: flex;
		align-items: center;
		&:first-child {
			margin-left: 0;
		}
	}

	&__logo {
		display: none;
		a {
			width: var(--navigation-logo-width);
			height: var(--navigation-logo-height);
		}
	}

	&__membership-anchor {
		margin-left: 0;
		&--logged-in {
			display: none;
		}
	}

	&__account {
		flex: 1;
		justify-content: flex-end;
	}

	@media (min-width: variables.$breakpoint-xs) {
		margin-right: calc(
			var(--dialog-close-button-icon-size) + var(--spacing-sm)
		);
	}
	@media (min-width: variables.$breakpoint-md) {
		margin-right: calc(
			var(--navigation-fullscreen-open-button-content-padding-inline) +
				var(--dialog-close-button-icon-size) + var(--spacing-xs)
		);
		margin-bottom: var(--spacing-md);
		&__anchor-list > * {
			margin: 0 var(--spacing-xs);
		}
	}
	@media (min-width: variables.$breakpoint-lg) {
		&__logo {
			display: block;
		}
		&__membership-anchor {
			justify-content: flex-end;
			order: 1;
			margin-left: var(--spacing-sm);
		}
	}
}
