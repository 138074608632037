@use 'mixins';
@use 'variables';

.footer {
	--button-background-color: var(--color-core-black);
	--button-text-color: var(--color-core-white);
	--button-border-color: transparent;

	padding: var(--spacing-md) var(--spacing-sm);

	background-color: var(--cart-dialog-background);
}

.total,
.subtotal,
.discount {
	display: flex;
	justify-content: space-between;
}

.subtotal,
.discount,
.note {
	@include mixins.font-t6($weight: 700);
}

.total {
	@include mixins.font-t5($weight: 700);
}

.note,
.total:not(:first-child) {
	margin-top: var(--spacing-sm);
}

.link {
	margin-top: var(--spacing-md);
}

@media (min-width: variables.$breakpoint-md) {
	.footer {
		padding: var(--spacing-md);
	}
}
