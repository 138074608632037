@keyframes marquee {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

.marquee {
	padding: var(--marquee-padding);

	.text:not(:first-child) {
		display: none;
	}

	&.is-venue {
		width: calc(var(--marquee-text-character-count) * 0.85em);
		--badge-background-color: var(--color-core-black);
		color: var(--color-core-white);
	}

	@media (prefers-reduced-motion: no-preference) {
		&.is-animated {
			--marquee-animation-duration: calc(
				var(--marquee-text-character-count, 0) *
					var(--marquee-duration-per-character, 300ms)
			);
			--marquee-animation: marquee var(--marquee-animation-duration) linear
				infinite;

			display: flex;
			padding: var(--marquee-padding) 0;
			overflow: hidden;

			white-space: nowrap;
		}

		&.is-animated .text {
			animation: var(--marquee-animation);

			text-indent: var(--marquee-text-indent, 0.25em);

			&:not(:first-child) {
				display: block;
			}
		}
	}
}
