@use 'variables';
@use 'mixins';

.container {
	--cart-dialog-background: var(--color-accent-concrete);
	--cart-max-width: 23.4375em; // Prevent a smallish gap on the left of the cart on common iPhone width of 375px
}

.overlay {
	--dialog-overlay-background: rgba(
		var(--body-background-color-rgb, var(--color-core-sand-rgb)),
		0.8
	);
	--dialog-overlay-backdrop-filter: saturate(0);
}

.openButton {
	touch-action: manipulation;
}

.dialog {
	display: flex;
	flex-direction: column;
	max-width: var(--cart-max-width);
	margin-left: auto;

	background-color: var(--cart-dialog-background);
}

.content {
	position: relative;
	z-index: 1;
	flex: 1 0 auto;
}

.closeButton {
	position: fixed;
	z-index: 2;
	// Align close button with the title
	top: calc(
		var(--spacing-md) +
			(
				#{variables.$line-height-t2} *
					1em - var(--dialog-close-button-icon-size)
			) /
			2
	);
	right: var(--spacing-sm);

	// The `font-size` is declared here for alignment purposes
	font-size: variables.$font-size-t2;
}

.title,
.footer {
	position: sticky;
	z-index: 2;
}

.title {
	@include mixins.font-t2;

	top: 0;
	padding: var(--spacing-md) var(--spacing-sm);

	border-bottom: 1px solid;

	background-color: inherit;
}

.footer {
	bottom: 0;

	border-top: 1px solid;
}

@media (min-width: variables.$breakpoint-md) {
	.container {
		--cart-max-width: 20em;
	}

	.title {
		padding: var(--spacing-md);
	}

	.closeButton {
		right: var(--spacing-md);
	}
}

@media (prefers-reduced-motion: no-preference) {
	.dialog {
		--dialog-content-transition-duration: 300ms;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.overlay {
		transition:
			backdrop-filter,
			background-color 300ms ease-in-out;
	}

	.container[aria-hidden='true'],
	.container[aria-hidden='true'] .overlay {
		display: block;
	}

	.dialog {
		transition: transform var(--dialog-content-transition-duration, 0)
			ease-in-out;
	}

	.container[aria-hidden='true'] .overlay {
		--dialog-overlay-background: transparent;
		--dialog-overlay-backdrop-filter: none;
	}

	.container[aria-hidden='true'] .dialog {
		transform: translateX(100%);
	}
}
