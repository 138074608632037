@use 'functions';

$breakpoint-xs: 22.5em;
$breakpoint-sm: 37.5em;
$breakpoint-md: 56.25em;
$breakpoint-lg: 75em;
$breakpoint-xl: 87.5em;
$breakpoint-2xl: 105em;

$font-size-t1: functions.fluid-length(
	$min: 5,
	$max: 11.25,
	$infinite: true,
);

$font-size-t2: functions.fluid-length(
	$min: 3,
	$max: 6,
	$infinite: true,
);

$font-size-t3: functions.fluid-length(
	$min: 2.25,
	$max: 3,
	$infinite: true,
);

$font-size-t4: functions.fluid-length(
	$min: 1.5,
	$max: 1.875,
	$infinite: true,
);

$font-size-t5: functions.fluid-length(
	$min: 1.125,
	$max: 1.5,
	$infinite: true,
);

$font-size-t6: functions.fluid-length(
	$min: 0.875,
	$max: 1,
	$infinite: true,
);

// Some of the values below are repeated: if one value updates the others don’t necessarily need updating which is why they are repeated.
$line-height-t1: 0.9;
$line-height-t2: 0.9;
$line-height-t3: 1.3;
$line-height-t3-title: 0.9;
$line-height-t4: 1.3;
$line-height-t5: 1.3;
$line-height-t6: 1.4;
$line-height-t7: 1.4;
