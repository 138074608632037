@use 'variables';
@use 'mixins';

.navigation-fullscreen-main {
	padding: 0 var(--spacing-sm);

	&__wrapper {
		max-width: 32rem;
		margin-right: auto;
		margin-left: auto;
	}

	&__link-blocks {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: var(--spacing-lg) var(--spacing-md);

		margin-bottom: var(--spacing-lg);

		&--location {
			order: -4;
		}
		&--map {
			order: -3;
			margin-top: calc(-1 * var(--spacing-sm));
			margin-left: calc(-1 * var(--spacing-md));
		}
		&--events {
			order: -2;
		}
		&--explore {
			order: -1;
		}
	}

	&__primary {
		margin-bottom: var(--spacing-lg);
	}

	&__newsletter {
		h2 {
			@include mixins.font-t6($weight: 700);
		}
		div {
			@include mixins.font-t6;
		}
		form {
			max-width: 20em;
			@include mixins.font-t6;
		}
	}

	@media (min-width: variables.$breakpoint-lg) {
		display: flex;
		padding: initial;

		&__wrapper {
			flex: 1 0 50%;
			max-width: initial;
			margin-right: initial;
			margin-left: initial;
		}

		&__link-blocks {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		&__link-blocks > * {
			order: initial;
		}

		&__primary {
			flex: 1 0 50%;
			padding-right: var(--spacing-xl);
		}
	}
}
