@use 'mixins';

.social,
.list {
	@include mixins.stack($size: sm);
}

.heading {
	@include mixins.font-t5($weight: 700);
}

.link {
	@include mixins.font-t5;
}
