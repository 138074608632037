@use 'mixins';
@use 'variables';

.video-banner {
	position: relative;

	background-color: var(--color-core-gravel);

	color: var(--color-core-sand);

	&__heading {
		@include mixins.font-t1;

		display: flex;
		position: absolute;
		z-index: 2;
		top: 0;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		height: 100%;

		pointer-events: none;

		> :first-child {
			// prevent run-in with the navigation open button
			margin-right: calc(
				var(--navigation-fullscreen-open-button-content-width) +
					var(--spacing-md)
			);
		}

		> :nth-child(even) {
			text-align: right;
		}
	}

	&__logo-wrapper {
		display: flex;
		position: absolute;
		z-index: 3;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: inherit;
	}

	&__logo {
		--logo-width: 54%;

		width: var(--logo-width);
		height: calc(var(--logo-width) / (var(--video-banner-logo-aspect-ratio)));

		fill: currentColor;
	}

	&__video {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (min-width: variables.$breakpoint-sm) {
		&__logo {
			--logo-width: 44%;
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&__logo {
			display: none;
		}
		&__heading {
			> :first-child {
				margin-right: calc(
					var(--navigation-fullscreen-open-button-content-width) +
						var(--spacing-xl) -
						(
							var(--navigation-fullscreen-open-button-content-padding-inline) *
								2
						)
				);
			}
		}
	}

	@media (min-width: variables.$breakpoint-lg) {
		--arrow-margin-inline: 3.625vw;
		--arrow-width: 1em;

		&__logo {
			--logo-width: 47%;
		}

		&__heading {
			> :last-child {
				//prevent run-in with the arrow
				margin-left: calc(var(--arrow-width) + 2 * var(--arrow-margin-inline));
			}
		}
	}
}
