@use 'variables';
@use 'mixins';

.navigation-container {
	position: fixed;
	z-index: 2;
	top: clamp(
		calc(-1 * var(--navigation-height)),
		calc(0px - var(--scroll-offset, 0px)),
		0px
	);
	left: 0;
	width: 100%;

	transition: var(--transition-top, none);

	background-color: var(--color-core-black);

	color: var(--color-core-sand);

	&--hide-mobile-header {
		top: clamp(
			calc(-1 * var(--navigation-height)),
			calc(-1 * var(--navigation-height) - var(--scroll-offset, 0px)),
			0px
		);
	}
}

.navigation__mobile-header {
	.navigation__anchor {
		@include mixins.font-t6($weight: 700);
	}
}

.navigation__mobile-header,
.navigation {
	display: flex;
	height: var(--navigation-height);
	padding: var(--spacing-xs) var(--spacing-sm);
}

.navigation {
	align-items: center;
	justify-content: space-between;
	gap: var(--spacing-sm);

	.navigation__account-button,
	&__account-button--hide {
		display: none;
	}

	&__anchor-list {
		display: flex;
		grid-gap: var(--spacing-sm);
		align-items: baseline;
		width: 100%;

		&--mobile-header {
			grid-gap: var(--spacing-xs);
			justify-content: flex-end;

			.navigation__anchor--membership {
				display: none;
			}
			.navigation__account-button--logged_in:not(
					.navigation__account-button--logged_in-tier-1
				)
				p {
				display: none;
			}
		}
		&--desktop {
			.navigation__anchor {
				display: none;
			}

			.navigation__anchor--visible-on-mobile {
				display: block;
			}

			.navigation__anchor--hide-before-xs-if-cart-visible {
				display: none;
			}

			.navigation__anchor--membership {
				@include mixins.font-t6;
			}

			.navigation__anchor--membership-logged-in {
				display: none !important;
			}
		}
	}

	&__logo {
		display: none;

		width: var(--navigation-logo-width);
		height: var(--navigation-logo-height);

		fill: currentColor;

		&--desktop {
			display: none;
		}
		&--mobile {
			display: block;
		}
	}

	&__cart {
		--cart-button-icon-fill: var(--color-core-sand);
		--cart-button-quantity-color: var(--color-core-black);

		display: flex;
		align-items: center;
	}
}

@media (min-width: variables.$breakpoint-xs) {
	.navigation__anchor-list--mobile-header .navigation__anchor--membership {
		display: block;
	}
	.navigation__anchor-list--mobile-header
		.navigation__account-button:not(.navigation__account-button--logged_in)
		svg {
		display: none;
	}
	.navigation__anchor-list--desktop {
		.navigation__anchor--visible-on-mobile {
			display: initial;
		}
	}
}

@media (min-width: 25em) {
	.navigation__anchor-list--mobile-header
		.navigation__account-button:not(.navigation__account-button--logged_in)
		svg {
		display: block;
	}
	.navigation__anchor-list--mobile-header
		.navigation__account-button--logged_in:not(
			.navigation__account-button--logged_in-tier-1
		)
		p {
		display: block;
	}
}

@media (min-width: variables.$breakpoint-sm) {
	.navigation__mobile-header,
	.navigation {
		padding: var(--spacing-sm) var(--spacing-md);
	}
	.navigation__anchor-list {
		grid-gap: var(--spacing-md);
	}
}

@media (min-width: variables.$breakpoint-md) {
	.navigation-container {
		position: relative;
		width: auto;

		background-color: transparent;

		color: currentColor;
	}

	.navigation__mobile-header {
		display: none;
	}

	.navigation {
		padding: var(--spacing-sm);
		padding-left: var(--spacing-lg);

		&__account-button:not(.navigation__account-button--hide) {
			display: flex;
			flex: initial;
			justify-content: flex-end;
		}

		&__anchor--membership {
			order: 1;
		}
		&__logo--desktop {
			display: block;
		}
		&__mobile_header,
		&__open-fullscreen-button {
			display: none;
		}
		&__anchor-list {
			justify-content: flex-end;
		}
		&__cart:not(&__cart--homepage) {
			--cart-button-icon-fill: var(--color-core-black);
			--cart-button-quantity-color: var(--color-core-sand);
		}
	}

	.navigation__anchor-list--desktop {
		.navigation__anchor--visible-on-mobile {
			display: none;
		}
		.navigation__anchor--visible-on-md {
			display: initial;
		}
	}

	// When authenticated the cart button in the `NavigationAddOn` is used
	.navigation__cart--authenticated {
		display: none;
	}
}

@media (min-width: variables.$breakpoint-lg) {
	.navigation__anchor-list--desktop {
		.navigation__anchor--visible-on-md {
			display: none;
		}
		.navigation__anchor--visible-on-lg {
			display: initial;
		}
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.navigation__anchor-list--desktop {
		.navigation__anchor {
			display: initial;
		}
	}
}
