@use 'mixins';

.navigation-anchor {
	--active-color: currentColor;
	--inactive-color: transparent;

	transition: text-decoration-color 200ms ease-in-out;

	text-decoration: underline;

	text-decoration-color: var(--inactive-color);
	text-decoration-thickness: var(--text-decoration-thickness);
	text-underline-offset: var(--text-underline-offset);

	&--is-active {
		text-decoration-color: var(--active-color);
	}

	@media (hover: hover) {
		&:hover {
			text-decoration-color: var(--active-color);
		}
	}
}
