@use 'variables';
@use 'mixins';

.layout {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	&--is-home {
		overflow-x: clip;
	}
}

// provide green “background” at the top of the event index page
#body-events .layout::before {
	content: '';

	display: block;
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: var(--navigation-height);

	background-color: var(--color-accent-seafoam);
}

// provide red “background” at the top of the elsewhere presents index page
#body-presents .layout::before {
	content: '';

	display: block;
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: var(--navigation-height);

	background-color: var(--color-accent-strawberry);
}

.layout > * {
	width: 100%;
}

.layout__video-banner {
	height: var(--svh-100);
}

.layout__video-banner,
.layout__content {
	position: relative;
	z-index: 1;
}

.layout__navigation,
.layout__sticky-navigation {
	width: auto;
}

.layout__navigation {
	flex: 1;
	height: calc(2 * var(--navigation-height));
}

.layout__navigation--home,
.layout__navigation--has-cover {
	z-index: 2;
}

.layout__navigation--home {
	margin-top: calc(-1 * var(--svh-100));

	color: var(--color-core-gravel);
}

.layout__sticky-navigation {
	display: flex;
	position: sticky;
	z-index: 2;
	top: 0;
	align-items: center;

	// make it the same height as the `Navigation` component
	height: var(--navigation-height);
	margin-left: auto;
	padding-right: var(--spacing-sm);

	outline-offset: -1px;
	gap: var(--spacing-xs);
}

.layout__video-banner ~ .layout__sticky-navigation {
	// make the sticky navigation sit at the top of the page
	margin-top: calc(var(--svh-100) * -1);
}

.layout__marquee {
	@include mixins.font-t1;

	margin: var(--spacing-md) 0;
}

.layout__notices {
	position: sticky;
	z-index: 2;
	right: 0;
	bottom: 0;
	left: 0;
}

@media (min-width: variables.$breakpoint-sm) {
	.layout__marquee {
		@include mixins.font-t2;
	}
}

@media (min-width: variables.$breakpoint-md) {
	.layout__navigation {
		flex: 1;
		height: var(--navigation-height);
	}

	.layout__sticky-navigation--home {
		*::before {
			background-color: var(
				--body-background-color-hex,
				var(--color-core-sand)
			) !important;
		}
		svg {
			fill: var(--color-core-black);
		}
	}

	.layout__sticky-navigation {
		padding-right: var(--spacing-lg);
		gap: var(--spacing-md);
	}

	.layout__navigation--has-cover {
		color: var(--color-core-black);
	}

	.layout__navigation--is-light-text {
		color: var(--color-core-gravel);
	}

	.layout__navigation--is-full-bleed-cover
		+ .layout__sticky-navigation--is-light-text
		*::before {
		background-color: var(--color-core-black) !important;
	}

	.layout__navigation--is-full-bleed-cover.layout__navigation--is-light-text {
		color: var(--color-core-white);

		svg {
			fill: var(--color-core-white);
		}
	}

	.layout__sticky-navigation--is-full-bleed-cover.layout__sticky-navigation--is-light-text:not(
			.layout__sticky-navigation--home
		) {
		svg {
			fill: var(--color-core-white);
		}
	}
}
