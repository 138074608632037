@use 'variables';

.container {
	top: unset;
	bottom: 0;
	height: auto;

	background-color: var(--color-accent-strawberry);
}

.dialog {
	overflow: hidden !important;
}

@media (min-width: variables.$breakpoint-sm) {
	.closeButton {
		top: var(--spacing-md);
		right: var(--spacing-md);
	}
}
