// based on https://moderncss.dev/totally-custom-list-styles/
// if it wasn’t for Safari we could be using https://developer.mozilla.org/en-US/docs/Web/CSS/::marker
// listitem text is wrapped in a div: https://moderncss.dev/totally-custom-list-styles/#gotcha-more-than-plain-text-as-li-content

.list {
	padding-left: 1ch;

	& > li {
		display: grid;
		grid-gap: 1ch;
		grid-template-columns: auto 1fr;
	}
}

.list:is(ol) {
	counter-reset: orderedlist;

	& > li::before {
		content: counter(orderedlist) '.';

		counter-increment: orderedlist;
	}
}

.list:is(ul) {
	list-style: none;

	& > li::before {
		content: '•';
	}
}
