@use 'variables';
@use 'mixins';

.navigation-fullscreen-footer {
	margin: var(--spacing-lg) var(--spacing-sm);
	margin-bottom: 0;

	&__info-pages {
		display: flex;

		flex-direction: column;
		max-width: 32rem;
		margin: 0 auto;
		gap: var(--spacing-xs);

		@include mixins.font-t6($weight: 500);
	}

	&__copyright {
		margin-top: var(--spacing-md);
	}

	@media (min-width: variables.$breakpoint-lg) {
		@include mixins.unstack;

		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: var(--spacing-sm) var(--spacing-md);

		margin-top: var(--spacing-xl);

		&__info-pages {
			flex-direction: row;
			justify-content: flex-end;
			max-width: initial;
			margin: initial;
			gap: var(--spacing-md);
		}

		&__copyright {
			margin-top: auto;
			margin-right: auto;
		}

		&__social {
			flex-direction: row;
			order: 2;
			gap: var(--spacing-md);
		}
	}
}
