@use 'mixins';

.font-t1 {
	@include mixins.font-t1;
}

.font-t2 {
	@include mixins.font-t2;
}

.font-t3-title {
	@include mixins.font-t3-title;
}

.font-t4 {
	@include mixins.font-t4;
}

.font-t4-bold {
	@include mixins.font-t4($weight: 700);
}

.font-t5 {
	@include mixins.font-t5;
}

.font-t5-bold {
	@include mixins.font-t5($weight: 700);
}

.font-t5-italic {
	@include mixins.font-t5($style: italic);
}

.font-t6 {
	@include mixins.font-t6;
}

.font-t6-medium {
	@include mixins.font-t6($weight: 500);
}

.font-t6-bold {
	@include mixins.font-t6($weight: 700);
}

.font-t7 {
	@include mixins.font-t7;
}
