@use 'mixins';
@use 'variables';

html {
	--svh-100: 100vh;

	--font-stack-sans: 'Everett', Arial, sans-serif;
	--font-stack-title: 'Serial', Impact, Arial Narrow, Arial, sans-serif;

	--text-underline-offset: 0.25em;
	--text-decoration-thickness: 1px;

	--color-core-white: hsl(0, 0%, 100%);
	--color-core-white-05: hsl(0, 0%, 100%, 0.05);
	--color-core-black: hsl(0, 0%, 0%);
	--color-core-black-05: hsl(0, 0%, 0%, 0.05);
	--color-core-sand: hsl(44, 67%, 88%);
	--color-core-sand-rgb: 245, 234, 204;
	--color-core-gravel: hsl(42, 18%, 86%);
	--color-accent-seafoam: hsl(142, 61%, 54%);
	--color-accent-watermelon: hsl(316, 76%, 74%);
	--color-accent-strawberry: hsl(6, 100%, 66%);
	--color-accent-concrete: hsl(51, 12%, 65%);
	--color-accent-dark-grey: hsl(0, 0%, 44%);
	--color-accent-silver: hsl(0, 0%, 70%);
	--color-accent-ocean: hsl(220, 100%, 63%);
	--color-accent-amethyst: hsl(240, 19%, 63%);
	--color-error: hsl(0, 100%, 40%);

	// current focus ring color
	--color-brand-baby-blue: hsl(185, 58%, 75%);

	// colors in use by the reservation widget
	--color-brand-pink: hsl(324, 100%, 91%);
	--color-brand-acid-green: hsl(80, 95%, 70%);

	--color-brand-discord: hsl(236, 83%, 65%);

	// gradient displayed over image in membership detail header
	--gradient-membership-detail-header: linear-gradient(
		180deg,
		rgba(201, 201, 201, 0) 16%,
		#737373 100%
	);

	--navigation-logo-width: 8rem;
	--navigation-logo-height: calc(
		var(--navigation-logo-width) / (var(--logo-aspect-ratio))
	);
	--navigation-height: calc(var(--navigation-logo-height) + var(--spacing-sm));

	--navigation-fullscreen-open-button-content-width: 4rem;
	--navigation-fullscreen-open-button-content-icon-size: 1.5rem;
	--navigation-fullscreen-open-button-content-padding-block: 0.8125rem;
	--navigation-fullscreen-open-button-content-padding-inline: 1.25rem;

	--dialog-close-button-icon-size: 1.5rem;

	--call-to-action-link-width: 8.5em;

	// svg aspect ratios (keep in sync with `public/svgs/sprite.svg`)
	--arrow-aspect-ratio: 20 / 14;
	--arrow-serial-output-aspect-ratio: 96 / 71;
	--call-to-action-arrow-aspect-ratio: 243 / 108;
	--hand-drawn-arrow-1-aspect-ratio: 692 / 705;
	--hand-drawn-arrow-2-aspect-ratio: 347 / 98;
	--hand-drawn-arrow-3-aspect-ratio: 121 / 209;
	--hand-drawn-arrow-4-aspect-ratio: 443 / 234;
	--hand-drawn-arrow-5-aspect-ratio: 91 / 37;
	--hand-drawn-star-aspect-ratio: 475 / 257;
	--hand-drawn-star-2-aspect-ratio: 142 / 119;
	--hand-drawn-stroke-1-aspect-ratio: 485 / 44;
	--hand-drawn-stroke-2-aspect-ratio: 814 / 30;
	--logo-aspect-ratio: 216 / 55;
	--video-banner-arrow-aspect-ratio: 166 / 194;
	--video-banner-logo-aspect-ratio: 333 / 94;
	--apple-icon-aspect-ratio: 50 / 61;
	--google-icon-aspect-ratio: 63 / 66;
	--discord-icon-aspect-ratio: 32 / 24;
	--map-drawing-aspect-ratio: 257 / 223;
	--membership-card-aspect-ratio: 1;

	@media (min-width: variables.$breakpoint-sm) {
		--call-to-action-link-width: 10em;
		--navigation-logo-width: 10.125rem;
		--navigation-height: calc(
			var(--navigation-logo-height) + var(--spacing-md)
		);
	}

	@media (min-width: variables.$breakpoint-md) {
		--navigation-logo-width: 13.5rem;
		--navigation-height: calc(
			var(--navigation-logo-height) + var(--spacing-xs) + var(--spacing-md)
		);
	}

	@supports (height: 100svh) {
		--svh-100: 100svh;
	}
}
