@use 'mixins';

html {
	--spacing-3xs: 0.125rem;
	--spacing-2xs: 0.25rem;
	--spacing-xs: 0.5rem;
	--spacing-sm: 1rem;
	--spacing-md: 2rem;
	--spacing-lg: 4rem;
	--spacing-xl: 8rem;
	--spacing-2xl: 16rem;
}

$sizes: ('3xs', '2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl');

@each $size in $sizes {
	.stack-#{$size} {
		@include mixins.stack($size);
	}

	.stack-vertical-#{$size} {
		@include mixins.stack-vertical($size);
	}
}
