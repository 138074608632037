@use 'mixins';

.list {
	@include mixins.stack($size: xs);
	@include mixins.font-t6;
}

.heading {
	@include mixins.font-t6($weight: 700);
}

.link {
	@include mixins.font-t6;
}
